// noinspection JSNonASCIINames,NonAsciiCharacters

import {Injectable, Injector} from '@angular/core';
import {EMPTY, finalize, Observable, Subject} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';
import {isArray} from 'lodash';

export const ɵON_DEMAND_LOADERS = '$ON_DEMAND_LOADERS';

export function OnDemandLoader(associatedName: string): MethodDecorator {
  return (target: any, key: string | symbol, descriptor: PropertyDescriptor): void => {
    if (!target[ɵON_DEMAND_LOADERS]) {
      target[ɵON_DEMAND_LOADERS] = [];
    }
    target[ɵON_DEMAND_LOADERS].push(associatedName);
    OnDemandResourceLoaderService.ɵRegisterMeta(associatedName, target.constructor, key as string);
  };
}

interface ILoaderData {
  loader: () => Observable<Array<any>>;
  isFetching: boolean;
  data: Array<any> | null;
  subject: Subject<any>;
}

@Injectable({
  providedIn: 'root'
})
export class OnDemandResourceLoaderService {
  private static ɵMetaResources: {[name: string]: {type: any; method: string;}} = {};
  private resources: { [name: string]: ILoaderData } = {};

  constructor(private injector: Injector) {
    console.log('$ OnDemandResourceLoaderService');
  }


  static ɵRegisterMeta(name: string, type: any, method: string): void {
    if (!!OnDemandResourceLoaderService.ɵMetaResources[name]) {
      throw new Error(`Attempt to register existed meta "${name}"`);
    }
    OnDemandResourceLoaderService.ɵMetaResources[name] = {
      type,
      method
    };
  }
  registerResourceLoader(name: string, loader: () => Observable<any>): OnDemandResourceLoaderService {
    if (this.resources[name]) {
      throw new Error(`Attempt to register existed resource "${name}"`);
    }
    this.resources[name] = {
      loader,
      data: null,
      isFetching: false,
      subject: new Subject<any>()
    }
    return this;
  }

  private ensureResourceRegistered(name: string): void {
    if (!this.resources[name]) {
      if (!!OnDemandResourceLoaderService.ɵMetaResources[name]) {
        const instance = this.injector.get(OnDemandResourceLoaderService.ɵMetaResources[name].type);
        this.registerResourceLoader(name, () => instance[OnDemandResourceLoaderService.ɵMetaResources[name].method]());
      } else {
        throw new Error(`On Demand Resource Loader is not registered "${name}"`);
      }
    }
  }

  isResourceRegistered(name: string): boolean {
    return !!this.resources[name];
  }

  observe(name: string): Observable<any> {
    this.ensureResourceRegistered(name);
    const loaderData = this.resources[name];
    if (loaderData.data != null) {
      loaderData.subject.next(loaderData.data);
    } else if (!loaderData.isFetching) {
      loaderData.isFetching = true;
      loaderData.loader()
        .pipe(
          tap((response) => {
            loaderData.data = response;
            loaderData.subject.next(loaderData.data);
          }),
          catchError((error) => {
            loaderData.data = [];
            console.error(`Can't fetch in On Demand Resource Loader ${name} due to the following:`, error);
            return EMPTY;
          }),
          finalize(() => loaderData.isFetching = false)
        ).subscribe();
    }
    return loaderData.subject;
  }

  takeOnce<T>(resourceName: string): Observable<T> {
    return this.observe(resourceName).pipe(take(1));
  }

  reset(resources: string | Array<string>): void {
     for (const name of isArray(resources) ? resources : [resources]) {
      this.ensureResourceRegistered(name);
      const loaderData = this.resources[name];
      if (loaderData.data) {
        loaderData.data = null;
      }
    }
  }
}
