import {
  APP_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  Inject,
  Injectable,
  InjectionToken,
  LOCALE_ID,
  Optional,
  Provider
} from '@angular/core';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {noop, Observable} from 'rxjs';
import {MenuItem, PrimeNGConfig} from 'primeng/api';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {MessageFormatConfig} from 'ngx-translate-messageformat-compiler/lib/message-format-config';
import {Settings} from 'luxon';
import {registerLocaleData} from '@angular/common';
import {getStorageItem, setStorageItem} from '../../util/util';
import {Router, RouteReuseStrategy} from '@angular/router';
import {ILocalized} from '../../types';
import {HttpBackend, HttpClient} from '@angular/common/http';

export const LOCALE_CONFIG_TOKEN = new InjectionToken<ILocaleConfig>('LocaleConfig');

export interface ILocaleConfig {
  locales: { [locale in string]: { name: string; data: any; } };
}

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LOCALE_NAME = 'English (United States)';

const FALLBACK_PREFIX = 'default::';

export class I18nLoader implements TranslateLoader {
  private httpClient: HttpClient = new HttpClient(inject(HttpBackend));

  constructor(public prefix: string = './assets/i18n/', public suffix: string = '.json') {
  }

  public getTranslation(lang: string): Observable<Object> {
    return this.httpClient.request('get', `${this.prefix}${lang}${this.suffix}`, {});
  }
}

export class MessageFormatTranslate extends TranslateMessageFormatCompiler {
  override compileTranslations(translations: any, lang: string): any {
    if (typeof translations === 'string') {
      return this.compile(translations, lang);
    }
    return Object.keys(translations).reduce<{ [key: string]: any }>(
      (acc, key) => {
        if (key === 'primeng') {
          acc[key] = translations[key];
        } else {
          const value = translations[key];
          acc[key] = this.compileTranslations(value, lang);
        }
        return acc;
      },
      {}
    );
  }
}

export class LocaleId extends String {
  constructor(private locale: LocaleService) {
    super();
  }

  override toString(): string {
    return this.locale.currentLocale;
  }

  override valueOf(): string {
    return this.toString();
  }
}

export function i18nProviders(): (Provider | EnvironmentProviders)[] {
  return [
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LOCALE,
      loader: {
        provide: TranslateLoader,
        useFactory: () => new I18nLoader()
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: MessageFormatTranslate
      }
    })),
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        fallbackPrefix: FALLBACK_PREFIX
      } satisfies MessageFormatConfig
    },
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
      deps: [LocaleService]
    }
  ];
}

export function i18Initializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (service: I18nService) => () => {
      return service.init();
    },
    deps: [I18nService],
    multi: true
  };
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private _currentLocale = DEFAULT_LOCALE;
  private _availableLocales: Array<string>;
  private _availableLocalesOptions: Array<{ label: string; value: string }>;

  constructor(@Optional() @Inject(LOCALE_CONFIG_TOKEN) localeConfig: ILocaleConfig) {
    this._availableLocales = Object.keys(localeConfig?.locales || {});
    this._availableLocales.forEach((id) => registerLocaleData(localeConfig!.locales[id].data));
    this._availableLocalesOptions = this._availableLocales.map((id) => ({
      label: localeConfig!.locales[id].name,
      value: id
    }));
    if (!this._availableLocales.includes(DEFAULT_LOCALE)) {
      this._availableLocales.unshift(DEFAULT_LOCALE);
      this._availableLocalesOptions.unshift({label: DEFAULT_LOCALE_NAME, value: DEFAULT_LOCALE});
    }
  }

  get currentLocale(): string {
    return this._currentLocale;
  }

  set currentLocale(value: string) {
    this._currentLocale = value;
  }

  get availableLocales(): Array<string> {
    return this._availableLocales;
  }

  get availableLocalesOptions() {
    return this._availableLocalesOptions;
  }

  isLocaleAvailable(localeId: string | undefined, strict = true): string | undefined {
    if (!localeId) {
      return undefined;
    }
    if (this.availableLocales.includes(localeId)) {
      return localeId;
    }
    if (strict) {
      return undefined;
    }
    localeId = localeId.slice(0, 2);
    return this.availableLocales.includes(localeId) ? localeId : undefined;
  }

}

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor(private translate: TranslateService,
              private config: PrimeNGConfig,
              private router: Router,
              private routeReuseStrategy: RouteReuseStrategy,
              public readonly localeService: LocaleService) {
  }


  init(): boolean {
    this.translate.addLangs(this.localeService.availableLocales);
    this.translate.setDefaultLang(DEFAULT_LOCALE);

    const saved = getStorageItem<{ id: string; }>('#locale');
    let localeId = this.localeService.isLocaleAvailable(saved?.id);
    if (!localeId) {
      localeId = this.localeService.isLocaleAvailable(navigator.language, false);
    }
    localeId = localeId || DEFAULT_LOCALE;
    this.assignLocale(localeId);
    return true;
  }

  private assignLocale(localeId: string): void {
    this.translate.use(localeId);
    this.translate.get('primeng').subscribe((res) => this.config.setTranslation(res));
    Settings.defaultLocale = localeId; // Luxon
    this.localeService.currentLocale = localeId;

  }

  setLocale(localeId: string) {
    setStorageItem('#locale', {id: localeId});
    this.assignLocale(localeId);
    this.refreshLocaleId();
  }

  private async refreshLocaleId(): Promise<void> {
    this.translate.onLangChange.subscribe(async () => {
      const fnShouldReuseRoute = this.routeReuseStrategy.shouldReuseRoute;
      this.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigated = false;
      await this.router.navigateByUrl(this.router.url).catch(noop);
      this.routeReuseStrategy.shouldReuseRoute = fnShouldReuseRoute;
    });
  }

}


@Injectable({
  providedIn: 'root'
})
export class TranslateHelperService {
  constructor(public readonly translate: TranslateService) {
  }

  translateMenu(menu: Array<MenuItem & ILocalized>): Array<MenuItem> {
    menu.forEach((item) => item.label = item.i18n ? this.translate.instant(item.i18n, item.interpolateParams) : item.label);
    return menu;
  }
}
