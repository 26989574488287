/* tslint:disable:object-literal-key-quotes */

export type TEmbeddedTypeWithPrefix<T, PREFIX extends string> = {
  [K in keyof T as `${ PREFIX }${ Capitalize<string & K> }`]: T[K];
};

export interface IIdentified {
  id: string;
}

export interface IDraftable {
  isDraft: boolean;
}

export interface INamed {
  name: string;
}

export interface ITimestamped {
  createdAt: Date;
  updatedAt: Date;
}

export interface IMailable {
  email: string;
}

export interface IAvatar {
  avatar?: string;
}

export interface IDeletable {
  isDeleted: boolean;
}

export interface ISearchable {
  search: string;
}

export interface IFullNamed {
  firstName: string;
  lastName: string;
}

export interface IAddress {
  city: string;
  country: string;
  state: string;
}

export interface IUrl {
  url: string;
}

export interface IArchivable {
  isArchived: boolean;
}

export const PERIOD_TYPES = ['day', 'week', 'month', 'quarter', 'year', 'range'] as const;
export type TPeriodType = typeof PERIOD_TYPES[number];

export interface IPeriod {
  start: Date;
  end: Date;
  type: TPeriodType;
}

export interface IAttachment {
  url: string;
  name: string;
  mimetype: string;
  size: number;
}

export interface ICountry extends INamed {
  code: string;
  flag?: string;
  forceState?: boolean;
}

export interface IState extends INamed {
  code: string;
  countryCode: string;
}

export const CONTRACT_TYPES = [
  'Employee - Hourly',
  'Employee - Recurring Fixed',
  'Contractor - Hourly',
  'Contractor - Monthly Fixed',
  'Contractor - One-Time Fixed',
  'TP SubContractor - Hourly',
  'TP SubContractor - Monthly Fixed',
  'TP SubContractor - One-Time Fixed'
] as const;

export type TContractType = typeof CONTRACT_TYPES[number];

export interface ITenantable {
  tenant: string;
}

export interface ITenant extends IIdentified, INamed, IAvatar, ITimestamped {
  description?: string;
}

export type TTenantSave = Omit<ITenant, 'createdAt' | 'updatedAt'>;
