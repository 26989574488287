import {InjectionToken} from '@angular/core';
import { IAuthProviderConfig } from './auth-provider/auth-provider';

export const COMMON_LIB_CONFIG_TOKEN = new InjectionToken<ICommonLibConfig>('CommonLibConfig')
export interface ICommonLibConfig {
  appName: string;
  apiUrl: string;
  authorizationUrl: string;
  imageServiceUrl: string;
  fileStorageUrl: string;
  authCustomConfig: {
    googleAuthUrl: string;
    passwordAuthUrl: string;
    refreshTokenUrl: string;
    userProfileApiUrl: string;
  },
  authProviderConfig: IAuthProviderConfig
}
